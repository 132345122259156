import { HttpClient } from '@/services/http';

export class TokenService extends HttpClient {
  async token(params) {
    const result = await this.post('v1/token', params);
    return result;
  }

  async partner(params) {
    const result = await this.post('v1/token/partner', params);
    return result;
  }
}
