<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    :max-width="400"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal"
  >
    <v-card>
      <v-card-title>
        <h3 class="font-weight-regular">Recuperar senha</h3>
        <v-spacer />
        <v-btn icon @click="closeModal" id="btn-close" name="btn-close">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          Será enviada uma mensagem no seu email com o link para mudar a sua
          senha e acessar o sistema caso cadastrado.
        </p>
        <v-form
          @submit.prevent="execute"
          ref="form"
          lazy-validation
          v-model="valid"
        >
          <v-text-field
            label="Informe seu email"
            id="email"
            name="email"
            outlined
            required
            :rules="[required, email]"
            v-model="login"
          />

          <BaseButton
            block
            id="btn-execute"
            name="btn-execute"
            type="submit"
            color="primary"
            title="Pedir uma nova senha"
            :disabled="!valid"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { showMessage } from '@/helpers/messages';
import { AccountService } from '@/services/api/account';

export default {
  mixins: [rulesMixin],

  data: () => ({
    valid: true,
    login: null
  }),

  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  computed: {
    theme() {
      return this.$vuetify.theme.isDark;
    }
  },

  methods: {
    async execute() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        await this.$recaptchaLoaded();
        const recaptchaToken = await this.$recaptcha('login');

        const accountService = new AccountService();
        const { status } = await accountService.forgotPassword({
          email: this.login,
          recaptchaToken
        });

        if (status === 204) {
          showMessage(
            'success',
            'Email contendo informações para recuperação de senha enviado com sucesso!'
          );
          this.closeModal();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    closeModal() {
      this.$emit('close');
    }
  }
};
</script>
