<template>
  <v-container class="b_login" v-resize="onResize">
    <v-row>
      <v-col xl="5" lg="5" md="5" sm="6" class="b_login__content">
        <div class="b_login__content--logo">
          <img
            :src="
              !theme
                ? require('@/assets/logo/default.svg')
                : require('@/assets/logo/white.svg')
            "
            alt="Logo Biobe"
          />
        </div>
        <div class="b_login__content--form">
          <div class="b_login__content--title">
            <h1>Bem vindo</h1>
            <span>Preencha os campos abaixo e acesse sua conta</span>
          </div>

          <v-form
            @submit.prevent="handleLogin"
            ref="form"
            lazy-validation
            v-model="valid"
          >
            <div class="b_login__content--fields">
              <v-text-field
                id="login"
                name="login"
                label="Login"
                outlined
                required
                :rules="[required]"
                v-model="login"
              />

              <BasePassword
                id="password"
                name="password"
                label="Senha"
                outlined
                required
                :rules="[required]"
                v-model="password"
              />
            </div>

            <BaseButton
              id="btn-login"
              name="btn-login"
              title="Acessar"
              type="submit"
              color="primary"
              block
              :disabled="!valid"
              :loading="isLoading"
            />

            <div class="b_login__content--forgot">
              <v-btn
                id="btn-forgot-password"
                name="btn-forgot-password"
                text
                :color="!theme ? 'secondary' : '#fff'"
                @click="dialog = !dialog"
              >
                <v-icon left :color="!theme ? 'primary' : '#fff'">
                  fa-solid fa-unlock-keyhole
                </v-icon>
                Esqueci minha senha
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
      <v-col xl="7" lg="7" md="7" sm="6" v-if="innerWidth >= 600">
        <div class="b_login__picture">
          <img
            :src="require('@/assets/bg/login.svg')"
            alt="Imagem de exibição de login"
          />
        </div>
      </v-col>
    </v-row>

    <div class="b_login__footer">
      <p>© Biobe {{ currentYear }}. Todos os direitos reservados</p>
    </div>

    <DialogForgotPassword v-if="dialog" :dialog="dialog" @close="closeModal" />
  </v-container>
</template>

<script>
import DialogForgotPassword from '@/components/account/dialog/dialog-forgot-password';
import { rulesMixin } from '@/mixins/rules';
import { meMixin } from '@/mixins/me';
import { setToken } from '@/helpers/token';
import { TokenService } from '@/services/api/token';

export default {
  mixins: [rulesMixin, meMixin],

  components: {
    DialogForgotPassword
  },

  data: () => ({
    currentYear: new Date().getFullYear(),
    valid: true,
    login: '',
    password: '',
    dialog: false,
    innerWidth: 0
  }),

  computed: {
    theme() {
      return this.$vuetify.theme.isDark;
    },

    isLoading() {
      return this.$store.getters['loading/isLoading'];
    }
  },

  methods: {
    async handleLogin() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        await this.$recaptchaLoaded();
        const recaptchaToken = await this.$recaptcha('login');

        const tokenService = new TokenService();
        const { status, data } = await tokenService.token({
          login: this.login,
          password: this.password,
          recaptchaToken,
          application: 'Portal'
        });

        if (status === 200) {
          setToken(data.token);
          this.$store.commit('authentication/setToken', data.token);
          this.getMe();
          this.$router.push({ name: 'dashboard' });
        }
      } catch (error) {
        this.$refs.recaptcha.reset();
        throw new Error(error);
      }
    },

    closeModal() {
      this.dialog = !this.dialog;
    },

    onResize() {
      this.innerWidth = window.innerWidth;
    }
  }
};
</script>
